<template>
    <div class="places">
        <h2>{{ title }}</h2>
        
        <div class="place-slider" :data-mode="mode">
            <div class="place-slider-item hidden" v-for="place in placesWithImage" :key="place.place_id" @click="goToPlace(place.place_id)" :data-place-id="place.place_id">
                <img v-if="place.image" class="place-slider-item-image" :src="place.image" :alt="place.name">
                <div v-if="place.image" class="place-slider-item-image-backdrop black-backdrop"></div>
                <div v-if="!place.image" class="place-slider-item-image-backdrop animated-background"></div>
                <div class="place-slider-item-content">
                    <h3>{{ place.name ?? place.city }}</h3>
                    <span class="place-slider-item-infos" v-if="place.name">
                        <span>{{ place.city }}</span>
                        <span>{{ place.country }}</span>
                    </span>
                    <span class="place-slider-item-infos" v-if="!place.name">
                        <span>{{ place.address_line2 }}</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Places',
    props: ['mode'],
    inject: ['store'],
    components: {
    },
    data() {
        return {
            places: [],
            placesWithImage: [],
            sliderIsDragging: false,
            title: '',
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.title = this.mode == 'places' ? 'Top places searched' : 'Top cities searched';
            this.places = this.mode == 'places' ? await this.$root.getTopPlacesFromApi() : await this.$root.getTopCitiesFromApi();
            
            if(this.places && this.places.length > 0) {
                await this.setPlacesWithImage();
            }
            
            CoreService.initSlider('.place-slider[data-mode=' + this.mode + ']');
        },
        
        // #region PLACES
        
        async setPlacesWithImage() {
            this.placesWithImage = [];
            
            for(let i = 0; i < this.places.length; i++) {
                let place = this.places[i];
                let wikiParams = place.name ? place.name : place.city + ', ' + place.country;
                let placeWikiPage = await this.$root.getWikiSearch(wikiParams);
                
                if(placeWikiPage) {
                    place.image = await this.$root.getWikiImageFull(placeWikiPage.pageid);
                    
                    // Image found
                    if(place.image) {
                        await CoreService.preloadImage(place.image).then(() => {
                            this.placesWithImage.push(place);
                            this.showPlace(place.place_id);
                        });
                    }
                    
                    // Image not found
                    else {
                        this.placesWithImage.push(place);
                        this.showPlace(place.place_id);
                    }
                }
                
                // No wiki page found
                else {
                    this.placesWithImage.push(place);
                    this.showPlace(place.place_id);
                }
            }
        },
        
        showPlace(placeId) {
            setTimeout(() => {
                $('.place-slider-item[data-place-id=' + placeId + ']').removeClass('hidden');
            }, 250);
        },
        
        goToPlace(placeId) {
            if(this.sliderIsDragging) return;
            this.$router.push({ name: 'explore', params: { placeId: placeId } });
        },
        
        // #endregion
    }
}
</script>

<style scoped>
@import "../assets/scss/trips.scss";
@import "../assets/scss/slider.scss";
</style>
