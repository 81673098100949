import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import axios from 'axios';

export default createStore({
    plugins: [createPersistedState()],
    
    state: {
        user: null,
        userId: null,
        token: null,
        isLoggedIn: false,
        tokenExpiration: null,
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
            state.isLoggedIn = true;
            
        },
        setUserId(state, userId) {
            localStorage.setItem('userId', userId);
            state.userId = userId;
        },
        setToken(state, token) {
            state.token = token;
            localStorage.setItem('token', token);
            
            // Extract expiration from token (if possible)
            try {
                const decodedToken = JSON.parse(atob(token.split('.')[1])); // Base64 decode
                state.tokenExpiration = new Date(decodedToken.exp * 1000); // Convert to milliseconds
                state.isLoggedIn = true;
            } catch (error) {
                console.error('Error parsing token:', error);
            }
        },
        removeToken(state) {
            state.token = null;
            localStorage.removeItem('token');
            state.isLoggedIn = false;
            state.tokenExpiration = null; // Reset expiration
        },
        removeUserId(state) {
            state.userId = null;
            localStorage.removeItem('userId');
        },
    },
    actions: {
        async register({ commit }, credentials) {
            try {
                if(credentials.code == 'Beverly') {
                    const response = await axios.post(process.env.VUE_APP_API_URL + '/register', credentials);
                    commit('login', credentials);
                }
                else {
                    throw new Error('Code is wrong. Please try again.');
                }
            } catch (error) {
                if(error && (error.response.status == 401 || error.response.status == 400)) {
                    throw new Error(error.response.data?.message);
                }
                
                else {
                    throw new Error('An error occurred, please retry later.');
                }
            }
        },
        
        async login({ commit }, credentials) {
            try {
                const response = await axios.post(process.env.VUE_APP_API_URL + '/login', credentials);
                commit('setToken', response.data.accessToken);
                commit('setUser', response.data.user);
                commit('setUserId', response.data.user.id);
            } catch (error) {
                if(error.response.status == 401) {
                    throw new Error(error.response.data?.message);
                }
                
                else {
                    throw new Error('An error occurred, please retry later.');
                }
            }
        },
        
        logout({ commit }) {
            commit('removeToken');
            commit('removeUserId');
        },
        
        // New action to retrieve token from local storage on load
        retrieveTokenFromStorage({ commit }) {
            const storedToken = localStorage.getItem('token');
            if (storedToken) {
                commit('setToken', storedToken);
            }
        },
        
        retrieveUserIdFromStorage({ commit }) {
            const storedUserId = localStorage.getItem('userId');
            if (storedUserId) {
                commit('setUserId', storedUserId);
            }
        },
    },
    getters: {
        isLoggedIn: state => state.isLoggedIn,
        isTokenValid: (state) => {
            if (!state.token) {
                return false;
            }
            return state.tokenExpiration && (new Date() < (new Date(state.tokenExpiration) - 60 * 1000));
        },
        userId: state => state.userId,
    },
});