<template>
    <nav>
        <ul>
            <li>
                <router-link :to="{ name: 'home' }">
                    <md-icon>home</md-icon>
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'map', params: { mode: 'explore' } }">
                    <md-icon>travel_explore</md-icon>
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'trip', params: { tripId: tripId } }">
                    <md-icon>multiple_stop</md-icon>
                </router-link>
            </li>
            <li>
                <a>
                    <md-icon @click="logout">logout</md-icon>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>

export default {
    name: 'Nav',
    inject: ['store'],
    components: {
    },
    props: ['tripId'],
    data() {
        return {
            selectedTrip: CoreService.getSelectedTripId(),
        }
    },
    created() {
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            
        },
        
        logout() {
            this.store.dispatch('logout');
            this.$router.push('/');
        },
    }
}
</script>

<style scoped>
@import "../assets/scss/nav.scss";
</style>
