<template>
    <div :class="'logo-container ' + sizeClass">
        <svg v-if="type == 'default'" xmlns="http://www.w3.org/2000/svg" viewBox="0.043153516948223114 -0.000001430511474609375 61.665428161621094 68.4352798461914">
            <defs>
                <linearGradient id="animated-gradient" y1="34.22" x2="61.69" y2="34.22" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#3876B1">
                        <animate attributeName="stop-color" values="#3876B1; #776CB6; #C25593; #3876B1" dur="10s" repeatCount="indefinite"/>
                    </stop>
                    <stop offset=".49" stop-color="#776CB6">
                        <animate attributeName="stop-color" values="#776CB6; #C25593; #3876B1; #776CB6" dur="10s" repeatCount="indefinite"/>
                    </stop>
                    <stop offset=".99" stop-color="#C25593">
                        <animate attributeName="stop-color" values="#C25593; #3876B1; #776CB6; #C25593" dur="10s" repeatCount="indefinite"/>
                    </stop>
                </linearGradient>
            </defs>
            <path d="M.16 43.64a39.5 39.5 0 0 0 0-5.22c-.57-4.18 1-7.43 3.52-10.67 6.47-8.29 12.67-16.8 19-25.23 2.52-3.36 4-3.36 6.57 0C33 7.61 36.84 12.66 40.59 17.78a2.21 2.21 0 0 0 3 .9c4.95-1.91 9.92-3.74 14.88-5.58 2.21-.82 2.83-.5 3 1.79.45 6.62.11 13.24.19 19.87a4 4 0 0 1-1 2.45q-11 14.73-22.07 29.45c-1.8 2.4-3.72 2.36-5.57-.08C29 61.37 25 56.14 21.16 50.86a2.44 2.44 0 0 0-3.37-.92c-4.73 1.87-9.51 3.63-14.28 5.39-2.63 1-3.32.47-3.35-2.27-.02-3.14 0-6.28 0-9.42zm35.64 3.13c1.33 0 5.57-4.8 5.42-6.15a2.05 2.05 0 0 0-.46-.92C36.53 34.08 32.32 28.45 28 22.88a2.56 2.56 0 0 0-4.27 0c-.89 1.07-1.63 2.27-2.53 3.33s-.79 2.05.05 3.14c4.06 5.32 8.07 10.66 12.1 16a3 3 0 0 0 2.45 1.42z" fill="url(#animated-gradient)"></path>
        </svg>
        
        <svg v-if="type == 'rounded'" data-v-0dd9719b="" version="1.0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 340.000000 250.000000" preserveAspectRatio="xMidYMid meet" color-interpolation-filters="sRGB" style="margin: auto;">
            <g data-v-0dd9719b="" fill="#333" class="j-b-g-c iconbgsvg" mask="url(#3ec2aacb-7e61-472f-a0ee-8b3013fa5447)" transform="translate(82.5,37.5)">
                <g data-d="container" transform="translate(0,0)">
                    <g>
                        <rect fill="#333" fill-opacity="0" stroke-width="2" x="0" y="0" width="175" height="175" class="image-rect"></rect>
                        <svg filter="url(#colors3646440594)" x="0" y="0" width="175" height="175" filtersec="colorsf4106916078" class="image-svg-container primary" style="overflow: visible;">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 249 249" fill-rule="evenodd" clip-rule="evenodd" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" style="overflow: visible;">
                                <g transform="scale(1, 1) skewX(0)">
                                    <path class="fil0" d="M125 0c68 0 124 56 124 125 0 68-56 124-124 124C56 249 0 193 0 125 0 56 56 0 125 0z" fill="#000000"></path>
                                </g>
                            </svg>
                        </svg>
                        <defs>
                            <filter id="colors3646440594">
                                <feColorMatrix type="matrix" values="0 0 0 0 0.21875  0 0 0 0 0.4609375  0 0 0 0 0.69140625  0 0 0 1 0" class="icon-fecolormatrix"></feColorMatrix>
                            </filter>
                            <filter id="colorsf4106916078">
                                <feColorMatrix type="matrix" values="0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 1 0" class="icon-fecolormatrix"></feColorMatrix>
                            </filter>
                            <filter id="colorsb6997187767">
                                <feColorMatrix type="matrix" values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 0" class="icon-fecolormatrix"></feColorMatrix>
                            </filter>
                        </defs>
                    </g>
                    <rect width="187" height="187" transform="translate(-6, -6)" style="pointer-events:none;" fill="url(#fg5GCBQ_JNzhmV_j-NXns)"></rect>
                </g>
                <g data-d="icon-text" hollow-target="textRect" transform="translate(38.900184631347656,33.564720153808594)">
                    <g data-d="icon" class="iconsvg-imagesvg" transform="translate(0,0)" mask="url(#3c62c13b-f23d-4071-bd35-a5427cacff68)">
                        <g>
                            <svg x="0" y="0" width="97.20000000000003" height="107.87055533359971" filtersec="colorsb9615096471" class="image-svg-svg primary" style="overflow: visible;">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0.043153516948223114 -0.000001430511474609375 61.665428161621094 68.4352798461914">
                                    <defs>
                                        <linearGradient id="09f890c3-d2cb-423c-911b-33bcf9dfdad4" y1="34.22" x2="61.69" y2="34.22" gradientUnits="userSpaceOnUse">
                                            <stop offset="0" stop-color="#3876B1"></stop>
                                            <stop offset=".49" stop-color="#776CB6"></stop>
                                            <stop offset=".99" stop-color="#C25593"></stop>
                                        </linearGradient>
                                    </defs>
                                    <path d="M.16 43.64a39.5 39.5 0 0 0 0-5.22c-.57-4.18 1-7.43 3.52-10.67 6.47-8.29 12.67-16.8 19-25.23 2.52-3.36 4-3.36 6.57 0C33 7.61 36.84 12.66 40.59 17.78a2.21 2.21 0 0 0 3 .9c4.95-1.91 9.92-3.74 14.88-5.58 2.21-.82 2.83-.5 3 1.79.45 6.62.11 13.24.19 19.87a4 4 0 0 1-1 2.45q-11 14.73-22.07 29.45c-1.8 2.4-3.72 2.36-5.57-.08C29 61.37 25 56.14 21.16 50.86a2.44 2.44 0 0 0-3.37-.92c-4.73 1.87-9.51 3.63-14.28 5.39-2.63 1-3.32.47-3.35-2.27-.02-3.14 0-6.28 0-9.42zm35.64 3.13c1.33 0 5.57-4.8 5.42-6.15a2.05 2.05 0 0 0-.46-.92C36.53 34.08 32.32 28.45 28 22.88a2.56 2.56 0 0 0-4.27 0c-.89 1.07-1.63 2.27-2.53 3.33s-.79 2.05.05 3.14c4.06 5.32 8.07 10.66 12.1 16a3 3 0 0 0 2.45 1.42z" fill="url(#09f890c3-d2cb-423c-911b-33bcf9dfdad4)"></path>
                                </svg>
                            </svg>
                        </g>
                        <rect width="109.19963073730469" height="119.87055969238281" transform="translate(-6, -6)" style="pointer-events:none;" fill="url(#fg5GCBQ_JNzhmV_j-NXns)"></rect>
                    </g>
                    <g data-d="text" transform="translate(48.599815368652344,114.87055969238281)">
                        <g fill-rule="" class="tp-name iconsvg-namesvg">
                            <g transform="scale(0.35999999999999927)">
                                <g>
                                    <path d="" transform="translate(0, 0)"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <defs>
                <filter id="3a37de6d-2ae2-4ac4-a38c-3c95ca517168">
                    <feColorMatrix type="matrix" values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 0"></feColorMatrix>
                </filter>
                <filter id="bb0a39fc-3f39-4e3d-8753-a5603de67ac0">
                    <feColorMatrix type="matrix" values="0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 1 0"></feColorMatrix>
                </filter>
            </defs>
            <mask id="2924d08c-c5d2-4c5b-a6ba-23c9e732fcdd">
                <g data-v-0dd9719b="" fill="#333" class="j-b-g-c iconbgsvg">
                    <g data-d="container" transform="translate(0,0)">
                        <g>
                            <rect fill="#333" fill-opacity="0" stroke-width="2" x="0" y="0" width="175" height="175" class="image-rect" stroke="#000"></rect>
                            <svg filter="url(#bb0a39fc-3f39-4e3d-8753-a5603de67ac0)" x="0" y="0" width="175" height="175" filtersec="colorsf4106916078" class="image-svg-container primary" style="overflow: visible;">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 249 249" fill-rule="evenodd" clip-rule="evenodd" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" style="overflow: visible;">
                                    <g transform="scale(1, 1) skewX(0)">
                                        <path class="fil0" d="M125 0c68 0 124 56 124 125 0 68-56 124-124 124C56 249 0 193 0 125 0 56 56 0 125 0z" fill="#000000"></path>
                                    </g>
                                </svg>
                            </svg>
                            <defs>
                                <filter id="colors3646440594">
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.21875  0 0 0 0 0.4609375  0 0 0 0 0.69140625  0 0 0 1 0" class="icon-fecolormatrix"></feColorMatrix>
                                </filter>
                                <filter id="colorsf4106916078">
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 1 0" class="icon-fecolormatrix"></feColorMatrix>
                                </filter>
                                <filter id="colorsb6997187767">
                                    <feColorMatrix type="matrix" values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 0" class="icon-fecolormatrix"></feColorMatrix>
                                </filter>
                            </defs>
                        </g>
                    </g>
                    <g data-d="icon-text" hollow-target="textRect" transform="translate(38.900184631347656,33.564720153808594)" filter="url(#3a37de6d-2ae2-4ac4-a38c-3c95ca517168)">
                        <g data-d="icon" class="iconsvg-imagesvg" transform="translate(0,0)">
                            <g>
                                <svg x="0" y="0" width="97.20000000000003" height="107.87055533359971" filtersec="colorsb9615096471" class="image-svg-svg primary" style="overflow: visible;">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0.043153516948223114 -0.000001430511474609375 61.665428161621094 68.4352798461914" style="overflow: visible;">
                                        <defs>
                                            <linearGradient id="09f890c3-d2cb-423c-911b-33bcf9dfdad4" y1="34.22" x2="61.69" y2="34.22" gradientUnits="userSpaceOnUse">
                                                <stop offset="0" stop-color="#3876B1"></stop>
                                                <stop offset=".49" stop-color="#776CB6"></stop>
                                                <stop offset=".99" stop-color="#C25593"></stop>
                                            </linearGradient>
                                        </defs>
                                        <path d="M.16 43.64a39.5 39.5 0 0 0 0-5.22c-.57-4.18 1-7.43 3.52-10.67 6.47-8.29 12.67-16.8 19-25.23 2.52-3.36 4-3.36 6.57 0C33 7.61 36.84 12.66 40.59 17.78a2.21 2.21 0 0 0 3 .9c4.95-1.91 9.92-3.74 14.88-5.58 2.21-.82 2.83-.5 3 1.79.45 6.62.11 13.24.19 19.87a4 4 0 0 1-1 2.45q-11 14.73-22.07 29.45c-1.8 2.4-3.72 2.36-5.57-.08C29 61.37 25 56.14 21.16 50.86a2.44 2.44 0 0 0-3.37-.92c-4.73 1.87-9.51 3.63-14.28 5.39-2.63 1-3.32.47-3.35-2.27-.02-3.14 0-6.28 0-9.42zm35.64 3.13c1.33 0 5.57-4.8 5.42-6.15a2.05 2.05 0 0 0-.46-.92C36.53 34.08 32.32 28.45 28 22.88a2.56 2.56 0 0 0-4.27 0c-.89 1.07-1.63 2.27-2.53 3.33s-.79 2.05.05 3.14c4.06 5.32 8.07 10.66 12.1 16a3 3 0 0 0 2.45 1.42z" fill="url(#09f890c3-d2cb-423c-911b-33bcf9dfdad4)"></path>
                                    </svg>
                                </svg>
                            </g>
                        </g>
                        <g data-d="text" transform="translate(48.599815368652344,114.87055969238281)">
                            <g fill-rule="" class="tp-name iconsvg-namesvg">
                                <g transform="scale(0.35999999999999927)">
                                    <g>
                                        <path d="" transform="translate(0, 0)"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </mask>
            <mask id="3c62c13b-f23d-4071-bd35-a5427cacff68">
                <g data-d="icon" class="iconsvg-imagesvg" filter="url(#ea6b4c81-badf-4722-bcf5-399ddcf979d4)">
                    <g>
                        <svg x="0" y="0" width="97.20000000000003" height="107.87055533359971" filtersec="colorsb9615096471" class="image-svg-svg primary" style="overflow: visible;">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0.043153516948223114 -0.000001430511474609375 61.665428161621094 68.4352798461914">
                                <defs>
                                    <linearGradient id="09f890c3-d2cb-423c-911b-33bcf9dfdad4" y1="34.22" x2="61.69" y2="34.22" gradientUnits="userSpaceOnUse">
                                        <stop offset="0" stop-color="#3876B1"></stop>
                                        <stop offset=".49" stop-color="#776CB6"></stop>
                                        <stop offset=".99" stop-color="#C25593"></stop>
                                    </linearGradient>
                                </defs>
                                <path d="M.16 43.64a39.5 39.5 0 0 0 0-5.22c-.57-4.18 1-7.43 3.52-10.67 6.47-8.29 12.67-16.8 19-25.23 2.52-3.36 4-3.36 6.57 0C33 7.61 36.84 12.66 40.59 17.78a2.21 2.21 0 0 0 3 .9c4.95-1.91 9.92-3.74 14.88-5.58 2.21-.82 2.83-.5 3 1.79.45 6.62.11 13.24.19 19.87a4 4 0 0 1-1 2.45q-11 14.73-22.07 29.45c-1.8 2.4-3.72 2.36-5.57-.08C29 61.37 25 56.14 21.16 50.86a2.44 2.44 0 0 0-3.37-.92c-4.73 1.87-9.51 3.63-14.28 5.39-2.63 1-3.32.47-3.35-2.27-.02-3.14 0-6.28 0-9.42zm35.64 3.13c1.33 0 5.57-4.8 5.42-6.15a2.05 2.05 0 0 0-.46-.92C36.53 34.08 32.32 28.45 28 22.88a2.56 2.56 0 0 0-4.27 0c-.89 1.07-1.63 2.27-2.53 3.33s-.79 2.05.05 3.14c4.06 5.32 8.07 10.66 12.1 16a3 3 0 0 0 2.45 1.42z" fill="url(#09f890c3-d2cb-423c-911b-33bcf9dfdad4)"></path>
                            </svg>
                        </svg>
                    </g>
                </g>
            </mask>
            <defs>
                <filter id="ea6b4c81-badf-4722-bcf5-399ddcf979d4" filterUnits="userSpaceOnUse">
                    <feColorMatrix type="matrix" values="0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 1 0"></feColorMatrix>
                </filter>
                <linearGradient x1="0" y1="1" x2="1" y2="0" id="fg5GCBQ_JNzhmV_j-NXns">
                    <stop offset="0%" stop-color="#3876b1"></stop>
                    <stop offset="50%" stop-color="#776cb6"></stop>
                    <stop offset="100%" stop-color="#c25593"></stop>
                </linearGradient>
                <filter id="42443b3f1de60f6b6fd3b6a9844b4764" filterUnits="objectBoundingBox">
                    <feColorMatrix type="matrix" values="0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 1 0"></feColorMatrix>
                </filter>
            </defs>
            <mask id="3ec2aacb-7e61-472f-a0ee-8b3013fa5447">
                <g data-v-0dd9719b="" fill="#333" class="j-b-g-c iconbgsvg" mask="url(#2924d08c-c5d2-4c5b-a6ba-23c9e732fcdd)" filter="url(#ea6b4c81-badf-4722-bcf5-399ddcf979d4)">
                    <g data-d="container" transform="translate(0,0)">
                        <g>
                            <rect fill="#333" fill-opacity="0" stroke-width="2" x="0" y="0" width="175" height="175" class="image-rect"></rect>
                            <svg x="0" y="0" width="175" height="175" filtersec="colorsf4106916078" class="image-svg-container primary" style="overflow: visible;">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 249 249" fill-rule="evenodd" clip-rule="evenodd" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" style="overflow: visible;">
                                    <g transform="scale(1, 1) skewX(0)">
                                        <path class="fil0" d="M125 0c68 0 124 56 124 125 0 68-56 124-124 124C56 249 0 193 0 125 0 56 56 0 125 0z" fill="#000000"></path>
                                    </g>
                                </svg>
                            </svg>
                            <defs>
                                <filter id="colors3646440594">
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.21875  0 0 0 0 0.4609375  0 0 0 0 0.69140625  0 0 0 1 0" class="icon-fecolormatrix"></feColorMatrix>
                                </filter>
                                <filter id="colorsf4106916078">
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 1 0" class="icon-fecolormatrix"></feColorMatrix>
                                </filter>
                                <filter id="colorsb6997187767">
                                    <feColorMatrix type="matrix" values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 0" class="icon-fecolormatrix"></feColorMatrix>
                                </filter>
                            </defs>
                        </g>
                    </g>
                    <g data-d="icon-text" hollow-target="textRect" transform="translate(38.900184631347656,33.564720153808594)">
                        <g data-d="icon" class="iconsvg-imagesvg" transform="translate(0,0)">
                            <g>
                                <svg x="0" y="0" width="97.20000000000003" height="107.87055533359971" filtersec="colorsb9615096471" class="image-svg-svg primary" style="overflow: visible;">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0.043153516948223114 -0.000001430511474609375 61.665428161621094 68.4352798461914">
                                        <defs>
                                            <linearGradient id="09f890c3-d2cb-423c-911b-33bcf9dfdad4" y1="34.22" x2="61.69" y2="34.22" gradientUnits="userSpaceOnUse">
                                                <stop offset="0" stop-color="#3876B1"></stop>
                                                <stop offset=".49" stop-color="#776CB6"></stop>
                                                <stop offset=".99" stop-color="#C25593"></stop>
                                            </linearGradient>
                                        </defs>
                                        <path d="M.16 43.64a39.5 39.5 0 0 0 0-5.22c-.57-4.18 1-7.43 3.52-10.67 6.47-8.29 12.67-16.8 19-25.23 2.52-3.36 4-3.36 6.57 0C33 7.61 36.84 12.66 40.59 17.78a2.21 2.21 0 0 0 3 .9c4.95-1.91 9.92-3.74 14.88-5.58 2.21-.82 2.83-.5 3 1.79.45 6.62.11 13.24.19 19.87a4 4 0 0 1-1 2.45q-11 14.73-22.07 29.45c-1.8 2.4-3.72 2.36-5.57-.08C29 61.37 25 56.14 21.16 50.86a2.44 2.44 0 0 0-3.37-.92c-4.73 1.87-9.51 3.63-14.28 5.39-2.63 1-3.32.47-3.35-2.27-.02-3.14 0-6.28 0-9.42zm35.64 3.13c1.33 0 5.57-4.8 5.42-6.15a2.05 2.05 0 0 0-.46-.92C36.53 34.08 32.32 28.45 28 22.88a2.56 2.56 0 0 0-4.27 0c-.89 1.07-1.63 2.27-2.53 3.33s-.79 2.05.05 3.14c4.06 5.32 8.07 10.66 12.1 16a3 3 0 0 0 2.45 1.42z" fill="url(#09f890c3-d2cb-423c-911b-33bcf9dfdad4)"></path>
                                    </svg>
                                </svg>
                            </g>
                        </g>
                        <g data-d="text" transform="translate(48.599815368652344,114.87055969238281)">
                            <g fill-rule="" class="tp-name iconsvg-namesvg">
                                <g transform="scale(0.35999999999999927)">
                                    <g>
                                        <path d="" transform="translate(0, 0)"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </mask>
        </svg>
        
        <svg v-if="type == 'squared'" data-v-0dd9719b="" version="1.0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 340.000000 250.000000" preserveAspectRatio="xMidYMid meet" color-interpolation-filters="sRGB" style="margin: auto;">
            <g data-v-0dd9719b="" fill="#333" class="j-b-g-c iconbgsvg" mask="url(#34b30051-122d-420e-8682-9533e7fb8b53)" transform="translate(82.5,37.5)">
                <g data-d="container" transform="translate(0,0)">
                    <g>
                        <rect fill="#333" fill-opacity="0" stroke-width="2" x="0" y="0" width="175" height="175" class="image-rect"></rect>
                        <svg filter="url(#colors4647491021)" x="0" y="0" width="175" height="175" filtersec="colorsf5941081437" class="image-svg-container primary" style="overflow: visible;">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" style="overflow: visible;">
                                <g transform="scale(1, 1) skewX(0)">
                                    <title>96 - Rounded (Solid)</title>
                                    <path d="M49.001 0H10.999C4.934 0 0 4.934 0 10.999v38.002C0 55.066 4.934 60 10.999 60h38.002C55.065 60 60 55.066 60 49.001V10.999C60 4.934 55.065 0 49.001 0" fill="#000" fill-rule="evenodd"></path>
                                </g>
                            </svg>
                        </svg>
                        <defs>
                            <filter id="colors4647491021">
                                <feColorMatrix type="matrix" values="0 0 0 0 0.21875  0 0 0 0 0.4609375  0 0 0 0 0.69140625  0 0 0 1 0" class="icon-fecolormatrix"></feColorMatrix>
                            </filter>
                            <filter id="colorsf5941081437">
                                <feColorMatrix type="matrix" values="0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 1 0" class="icon-fecolormatrix"></feColorMatrix>
                            </filter>
                            <filter id="colorsb1267205246">
                                <feColorMatrix type="matrix" values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 0" class="icon-fecolormatrix"></feColorMatrix>
                            </filter>
                        </defs>
                    </g>
                    <rect width="187" height="187" transform="translate(-6, -6)" style="pointer-events:none;" fill="url(#02w7_c_Lq_neguFgqySrq)"></rect>
                </g>
                <g data-d="icon-text" hollow-target="textRect" transform="translate(38.900184631347656,33.564720153808594)">
                    <g data-d="icon" class="iconsvg-imagesvg" transform="translate(0,0)" mask="url(#64eadcb9-368b-4404-9e9d-b59c04710931)">
                        <g>
                            <svg x="0" y="0" width="97.20000000000003" height="107.87055533359971" filtersec="colorsb5096310027" class="image-svg-svg primary" style="overflow: visible;">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0.043153516948223114 -0.000001430511474609375 61.665428161621094 68.4352798461914">
                                    <defs>
                                        <linearGradient id="09f890c3-d2cb-423c-911b-33bcf9dfdad4" y1="34.22" x2="61.69" y2="34.22" gradientUnits="userSpaceOnUse">
                                            <stop offset="0" stop-color="#3876B1"></stop>
                                            <stop offset=".49" stop-color="#776CB6"></stop>
                                            <stop offset=".99" stop-color="#C25593"></stop>
                                        </linearGradient>
                                    </defs>
                                    <path d="M.16 43.64a39.5 39.5 0 0 0 0-5.22c-.57-4.18 1-7.43 3.52-10.67 6.47-8.29 12.67-16.8 19-25.23 2.52-3.36 4-3.36 6.57 0C33 7.61 36.84 12.66 40.59 17.78a2.21 2.21 0 0 0 3 .9c4.95-1.91 9.92-3.74 14.88-5.58 2.21-.82 2.83-.5 3 1.79.45 6.62.11 13.24.19 19.87a4 4 0 0 1-1 2.45q-11 14.73-22.07 29.45c-1.8 2.4-3.72 2.36-5.57-.08C29 61.37 25 56.14 21.16 50.86a2.44 2.44 0 0 0-3.37-.92c-4.73 1.87-9.51 3.63-14.28 5.39-2.63 1-3.32.47-3.35-2.27-.02-3.14 0-6.28 0-9.42zm35.64 3.13c1.33 0 5.57-4.8 5.42-6.15a2.05 2.05 0 0 0-.46-.92C36.53 34.08 32.32 28.45 28 22.88a2.56 2.56 0 0 0-4.27 0c-.89 1.07-1.63 2.27-2.53 3.33s-.79 2.05.05 3.14c4.06 5.32 8.07 10.66 12.1 16a3 3 0 0 0 2.45 1.42z" fill="url(#09f890c3-d2cb-423c-911b-33bcf9dfdad4)"></path>
                                </svg>
                            </svg>
                        </g>
                        <rect width="109.19963073730469" height="119.87055969238281" transform="translate(-6, -6)" style="pointer-events:none;" fill="url(#02w7_c_Lq_neguFgqySrq)"></rect>
                    </g>
                    <g data-d="text" transform="translate(48.599815368652344,114.87055969238281)">
                        <g fill-rule="" class="tp-name iconsvg-namesvg">
                            <g transform="scale(0.35999999999999927)">
                                <g>
                                    <path d="" transform="translate(0, 0)"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <defs>
                <filter id="a82247d0-ace9-4219-aeb9-4111a98bf6de">
                    <feColorMatrix type="matrix" values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 0"></feColorMatrix>
                </filter>
                <filter id="e4eef320-f5f9-4502-9c91-ad5cff74b69a">
                    <feColorMatrix type="matrix" values="0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 1 0"></feColorMatrix>
                </filter>
            </defs>
            <mask id="b437dcd5-6fd2-4bca-9e6d-0b04c0e6f66e">
                <g data-v-0dd9719b="" fill="#333" class="j-b-g-c iconbgsvg">
                    <g data-d="container" transform="translate(0,0)">
                        <g>
                            <rect fill="#333" fill-opacity="0" stroke-width="2" x="0" y="0" width="175" height="175" class="image-rect" stroke="#000"></rect>
                            <svg filter="url(#e4eef320-f5f9-4502-9c91-ad5cff74b69a)" x="0" y="0" width="175" height="175" filtersec="colorsf5941081437" class="image-svg-container primary" style="overflow: visible;">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" style="overflow: visible;">
                                    <g transform="scale(1, 1) skewX(0)">
                                        <title>96 - Rounded (Solid)</title>
                                        <path d="M49.001 0H10.999C4.934 0 0 4.934 0 10.999v38.002C0 55.066 4.934 60 10.999 60h38.002C55.065 60 60 55.066 60 49.001V10.999C60 4.934 55.065 0 49.001 0" fill="#000" fill-rule="evenodd"></path>
                                    </g>
                                </svg>
                            </svg>
                            <defs>
                                <filter id="colors4647491021">
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.21875  0 0 0 0 0.4609375  0 0 0 0 0.69140625  0 0 0 1 0" class="icon-fecolormatrix"></feColorMatrix>
                                </filter>
                                <filter id="colorsf5941081437">
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 1 0" class="icon-fecolormatrix"></feColorMatrix>
                                </filter>
                                <filter id="colorsb1267205246">
                                    <feColorMatrix type="matrix" values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 0" class="icon-fecolormatrix"></feColorMatrix>
                                </filter>
                            </defs>
                        </g>
                    </g>
                    <g data-d="icon-text" hollow-target="textRect" transform="translate(38.900184631347656,33.564720153808594)" filter="url(#a82247d0-ace9-4219-aeb9-4111a98bf6de)">
                        <g data-d="icon" class="iconsvg-imagesvg" transform="translate(0,0)">
                            <g>
                                <svg x="0" y="0" width="97.20000000000003" height="107.87055533359971" filtersec="colorsb5096310027" class="image-svg-svg primary" style="overflow: visible;">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0.043153516948223114 -0.000001430511474609375 61.665428161621094 68.4352798461914" style="overflow: visible;">
                                        <defs>
                                            <linearGradient id="09f890c3-d2cb-423c-911b-33bcf9dfdad4" y1="34.22" x2="61.69" y2="34.22" gradientUnits="userSpaceOnUse">
                                                <stop offset="0" stop-color="#3876B1"></stop>
                                                <stop offset=".49" stop-color="#776CB6"></stop>
                                                <stop offset=".99" stop-color="#C25593"></stop>
                                            </linearGradient>
                                        </defs>
                                        <path d="M.16 43.64a39.5 39.5 0 0 0 0-5.22c-.57-4.18 1-7.43 3.52-10.67 6.47-8.29 12.67-16.8 19-25.23 2.52-3.36 4-3.36 6.57 0C33 7.61 36.84 12.66 40.59 17.78a2.21 2.21 0 0 0 3 .9c4.95-1.91 9.92-3.74 14.88-5.58 2.21-.82 2.83-.5 3 1.79.45 6.62.11 13.24.19 19.87a4 4 0 0 1-1 2.45q-11 14.73-22.07 29.45c-1.8 2.4-3.72 2.36-5.57-.08C29 61.37 25 56.14 21.16 50.86a2.44 2.44 0 0 0-3.37-.92c-4.73 1.87-9.51 3.63-14.28 5.39-2.63 1-3.32.47-3.35-2.27-.02-3.14 0-6.28 0-9.42zm35.64 3.13c1.33 0 5.57-4.8 5.42-6.15a2.05 2.05 0 0 0-.46-.92C36.53 34.08 32.32 28.45 28 22.88a2.56 2.56 0 0 0-4.27 0c-.89 1.07-1.63 2.27-2.53 3.33s-.79 2.05.05 3.14c4.06 5.32 8.07 10.66 12.1 16a3 3 0 0 0 2.45 1.42z" fill="url(#09f890c3-d2cb-423c-911b-33bcf9dfdad4)"></path>
                                    </svg>
                                </svg>
                            </g>
                        </g>
                        <g data-d="text" transform="translate(48.599815368652344,114.87055969238281)">
                            <g fill-rule="" class="tp-name iconsvg-namesvg">
                                <g transform="scale(0.35999999999999927)">
                                    <g>
                                        <path d="" transform="translate(0, 0)"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </mask>
            <mask id="64eadcb9-368b-4404-9e9d-b59c04710931">
                <g data-d="icon" class="iconsvg-imagesvg" filter="url(#600ee1da-d328-47f4-996e-93eefb090589)">
                    <g>
                        <svg x="0" y="0" width="97.20000000000003" height="107.87055533359971" filtersec="colorsb5096310027" class="image-svg-svg primary" style="overflow: visible;">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0.043153516948223114 -0.000001430511474609375 61.665428161621094 68.4352798461914">
                                <defs>
                                    <linearGradient id="09f890c3-d2cb-423c-911b-33bcf9dfdad4" y1="34.22" x2="61.69" y2="34.22" gradientUnits="userSpaceOnUse">
                                        <stop offset="0" stop-color="#3876B1"></stop>
                                        <stop offset=".49" stop-color="#776CB6"></stop>
                                        <stop offset=".99" stop-color="#C25593"></stop>
                                    </linearGradient>
                                </defs>
                                <path d="M.16 43.64a39.5 39.5 0 0 0 0-5.22c-.57-4.18 1-7.43 3.52-10.67 6.47-8.29 12.67-16.8 19-25.23 2.52-3.36 4-3.36 6.57 0C33 7.61 36.84 12.66 40.59 17.78a2.21 2.21 0 0 0 3 .9c4.95-1.91 9.92-3.74 14.88-5.58 2.21-.82 2.83-.5 3 1.79.45 6.62.11 13.24.19 19.87a4 4 0 0 1-1 2.45q-11 14.73-22.07 29.45c-1.8 2.4-3.72 2.36-5.57-.08C29 61.37 25 56.14 21.16 50.86a2.44 2.44 0 0 0-3.37-.92c-4.73 1.87-9.51 3.63-14.28 5.39-2.63 1-3.32.47-3.35-2.27-.02-3.14 0-6.28 0-9.42zm35.64 3.13c1.33 0 5.57-4.8 5.42-6.15a2.05 2.05 0 0 0-.46-.92C36.53 34.08 32.32 28.45 28 22.88a2.56 2.56 0 0 0-4.27 0c-.89 1.07-1.63 2.27-2.53 3.33s-.79 2.05.05 3.14c4.06 5.32 8.07 10.66 12.1 16a3 3 0 0 0 2.45 1.42z" fill="url(#09f890c3-d2cb-423c-911b-33bcf9dfdad4)"></path>
                            </svg>
                        </svg>
                    </g>
                </g>
            </mask>
            <defs>
                <filter id="600ee1da-d328-47f4-996e-93eefb090589" filterUnits="userSpaceOnUse">
                    <feColorMatrix type="matrix" values="0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 1 0"></feColorMatrix>
                </filter>
                <linearGradient x1="0" y1="1" x2="1" y2="0" id="02w7_c_Lq_neguFgqySrq">
                    <stop offset="0%" stop-color="#3876b1"></stop>
                    <stop offset="50%" stop-color="#776cb6"></stop>
                    <stop offset="100%" stop-color="#c25593"></stop>
                </linearGradient>
                <filter id="42443b3f1de60f6b6fd3b6a9844b4764" filterUnits="objectBoundingBox">
                    <feColorMatrix type="matrix" values="0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 1 0"></feColorMatrix>
                </filter>
            </defs>
            <mask id="34b30051-122d-420e-8682-9533e7fb8b53">
                <g data-v-0dd9719b="" fill="#333" class="j-b-g-c iconbgsvg" mask="url(#b437dcd5-6fd2-4bca-9e6d-0b04c0e6f66e)" filter="url(#600ee1da-d328-47f4-996e-93eefb090589)">
                    <g data-d="container" transform="translate(0,0)">
                        <g>
                            <rect fill="#333" fill-opacity="0" stroke-width="2" x="0" y="0" width="175" height="175" class="image-rect"></rect>
                            <svg x="0" y="0" width="175" height="175" filtersec="colorsf5941081437" class="image-svg-container primary" style="overflow: visible;">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" style="overflow: visible;">
                                    <g transform="scale(1, 1) skewX(0)">
                                        <title>96 - Rounded (Solid)</title>
                                        <path d="M49.001 0H10.999C4.934 0 0 4.934 0 10.999v38.002C0 55.066 4.934 60 10.999 60h38.002C55.065 60 60 55.066 60 49.001V10.999C60 4.934 55.065 0 49.001 0" fill="#000" fill-rule="evenodd"></path>
                                    </g>
                                </svg>
                            </svg>
                            <defs>
                                <filter id="colors4647491021">
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.21875  0 0 0 0 0.4609375  0 0 0 0 0.69140625  0 0 0 1 0" class="icon-fecolormatrix"></feColorMatrix>
                                </filter>
                                <filter id="colorsf5941081437">
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 0 0.99609375  0 0 0 1 0" class="icon-fecolormatrix"></feColorMatrix>
                                </filter>
                                <filter id="colorsb1267205246">
                                    <feColorMatrix type="matrix" values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 0" class="icon-fecolormatrix"></feColorMatrix>
                                </filter>
                            </defs>
                        </g>
                    </g>
                    <g data-d="icon-text" hollow-target="textRect" transform="translate(38.900184631347656,33.564720153808594)">
                        <g data-d="icon" class="iconsvg-imagesvg" transform="translate(0,0)">
                            <g>
                                <svg x="0" y="0" width="97.20000000000003" height="107.87055533359971" filtersec="colorsb5096310027" class="image-svg-svg primary" style="overflow: visible;">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0.043153516948223114 -0.000001430511474609375 61.665428161621094 68.4352798461914">
                                        <defs>
                                            <linearGradient id="09f890c3-d2cb-423c-911b-33bcf9dfdad4" y1="34.22" x2="61.69" y2="34.22" gradientUnits="userSpaceOnUse">
                                                <stop offset="0" stop-color="#3876B1"></stop>
                                                <stop offset=".49" stop-color="#776CB6"></stop>
                                                <stop offset=".99" stop-color="#C25593"></stop>
                                            </linearGradient>
                                        </defs>
                                        <path d="M.16 43.64a39.5 39.5 0 0 0 0-5.22c-.57-4.18 1-7.43 3.52-10.67 6.47-8.29 12.67-16.8 19-25.23 2.52-3.36 4-3.36 6.57 0C33 7.61 36.84 12.66 40.59 17.78a2.21 2.21 0 0 0 3 .9c4.95-1.91 9.92-3.74 14.88-5.58 2.21-.82 2.83-.5 3 1.79.45 6.62.11 13.24.19 19.87a4 4 0 0 1-1 2.45q-11 14.73-22.07 29.45c-1.8 2.4-3.72 2.36-5.57-.08C29 61.37 25 56.14 21.16 50.86a2.44 2.44 0 0 0-3.37-.92c-4.73 1.87-9.51 3.63-14.28 5.39-2.63 1-3.32.47-3.35-2.27-.02-3.14 0-6.28 0-9.42zm35.64 3.13c1.33 0 5.57-4.8 5.42-6.15a2.05 2.05 0 0 0-.46-.92C36.53 34.08 32.32 28.45 28 22.88a2.56 2.56 0 0 0-4.27 0c-.89 1.07-1.63 2.27-2.53 3.33s-.79 2.05.05 3.14c4.06 5.32 8.07 10.66 12.1 16a3 3 0 0 0 2.45 1.42z" fill="url(#09f890c3-d2cb-423c-911b-33bcf9dfdad4)"></path>
                                    </svg>
                                </svg>
                            </g>
                        </g>
                        <g data-d="text" transform="translate(48.599815368652344,114.87055969238281)">
                            <g fill-rule="" class="tp-name iconsvg-namesvg">
                                <g transform="scale(0.35999999999999927)">
                                    <g>
                                        <path d="" transform="translate(0, 0)"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </mask>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'Logo',
    props: ['sizeClass', 'type'],
};
</script>