import { createWebHistory, createRouter } from "vue-router";
import store from '@/store.js';

import Home from "@/components/Home.vue";
import FinderMap from "@/components/FinderMap.vue";
import Trip from "@/components/Trip.vue";

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        props: true,
    },
    {
        path: '/Map/:mode?/:departureNeeded?/:arrivalNeeded?/:nightNeeded?/:placeId?',
        name: 'map',
        component: FinderMap,
        props: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/Trip/:tripId?/:stepToAdd?',
        name: 'trip',
        component: Trip,
        props: true,
        meta: {
            requiresAuth: true,
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: "active",
    routes,
});

router.beforeEach((to, from, next) => {
    // Check if token is expired
    store.dispatch('retrieveTokenFromStorage');
    store.dispatch('retrieveUserIdFromStorage');

    // Check if the route requires authentication
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.isLoggedIn) {
            next({
                name: 'home',
                query: { redirect: to.fullPath }
            });
        } 
        else {
            next();
        }
    } 
    
    else {
        next();
    }
});

export default router;