<template>
    <div class="register-form-container">
        <md-outlined-text-field id="registerEmailTextField" v-model="formData.email" label="Email" type="email" autocomplete="off" required minLength="5"></md-outlined-text-field>
        <md-outlined-text-field id="registerPasswordTextField" v-model="formData.password" label="Password" type="password" autocomplete="off" required minLength="8"></md-outlined-text-field>
        <md-outlined-text-field id="registerCodeTextField" v-model="formData.code" label="Code" type="text" autocomplete="off" required></md-outlined-text-field>
        
        <md-filled-button @click="submitForm" id="registerButton" disabled>Register</md-filled-button>
    </div>
</template>

<script>
export default {
    name: 'Register',
    inject: ['store'],
    data() {
        return {
            formData: {
                id: null,
                email: '',
                password: '',
                code: '',
            }
        };
    },
    mounted() {
        this.initFormEvents();
    },
    methods: {
        async submitForm() {
            try {
                let credentials = {
                    id: CoreService.getGuid(),
                    email: registerEmailTextField.value,
                    password: registerPasswordTextField.value,
                    code: registerCodeTextField.value,
                };
                await this.store.dispatch('register', credentials);

                console.log('Registration successful');
                registerButton.disabled = true;
                backToLoginButton.click();

                setTimeout(() => {
                    loginEmailTextField.value = credentials.email;
                }, 1000);
            } catch (error) {
                let errorMessage = error.toString().replace('Error: ', '');
                
                if(registerEmailTextField) {
                    registerEmailTextField.error = true;
                    registerEmailTextField.errorText = errorMessage;
                }
            }
        },
        
        initFormEvents() {
            registerEmailTextField.addEventListener('keyup', () => {
                this.checkForm();
            });
            
            registerEmailTextField.addEventListener('change', () => {
                this.checkForm();
            });
            
            registerPasswordTextField.addEventListener('keyup', () => {
                this.checkForm();
            });
            
            registerPasswordTextField.addEventListener('change', () => {
                this.checkForm();
            });
        },
        
        checkForm() {
            if(registerEmailTextField.validity.valid && registerPasswordTextField.validity.valid) {
                registerButton.disabled = false;
            }
            
            else {
                registerButton.disabled = true;
            }
        },
    }
};
</script>