import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router';
import store from './store';
import VueGtag from 'vue-gtag';
import { CoreService } from '@/assets/js/core';
import './registerServiceWorker'

const app = createApp(App).use(store).use(router);
app.use(VueGtag, {
    config: { id: 'G-N7CJMF7CHP' }  // Remplacez par votre ID de suivi
}, router);

app.config.globalProperties.$coreService = CoreService;
app.mount('#app');
