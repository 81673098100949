<template>
    <div class="login-form-container">
        <md-outlined-text-field id="loginEmailTextField" v-model="formData.email" label="Email" type="email" autocomplete="off" required minLength="5"></md-outlined-text-field>
        <md-outlined-text-field id="loginPasswordTextField" v-model="formData.password" label="Password" type="password" autocomplete="off" required minLength="8">
            <md-icon-button toggle slot="trailing-icon" id="passwordToggleButton">
                <md-icon>visibility</md-icon>
                <md-icon slot="selected">visibility_off</md-icon>
            </md-icon-button>
        </md-outlined-text-field>
        
        <md-filled-button @click="submitForm" id="loginButton" disabled>Login</md-filled-button>
    </div>
</template>

<script>
export default {
    name: 'Login',
    inject: ['store'],
    data() {
        return {
            formData: {
                email: '',
                password: ''
            }
        };
    },
    mounted() {
        this.initFormEvents();
    },
    methods: {
        async submitForm() {
            try {
                await this.store.dispatch('login', {
                    email: loginEmailTextField.value,
                    password: loginPasswordTextField.value,
                });
                this.$router.push('/');
            } catch (error) {
                let errorMessage = error.toString().replace('Error: ', '');
                
                if(loginEmailTextField) {
                    loginEmailTextField.error = true;
                    loginEmailTextField.errorText = errorMessage;
                }
            }
        },
        
        initFormEvents() {
            loginEmailTextField.addEventListener('keyup', () => {
                this.checkForm();
            });
            
            loginEmailTextField.addEventListener('change', () => {
                this.checkForm();
            });
            
            loginPasswordTextField.addEventListener('keyup', () => {
                this.checkForm();
            });
            
            loginPasswordTextField.addEventListener('change', () => {
                this.checkForm();
            });
            
            passwordToggleButton.addEventListener('click', (e) => {
                passwordToggleButton.parentElement.type = passwordToggleButton.parentElement.type == 'password' ? 'text' : 'password';
            });
        },
        
        checkForm() {
            if(loginEmailTextField.validity.valid && loginPasswordTextField.validity.valid) {
                loginButton.disabled = false;
            }
            
            else {
                loginButton.disabled = true;
            }
        },
    }
};
</script>